export const SET_MESSAGE = "SET_MESSAGE";

//alertas
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

//login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GET_USER = "GET_USER";
export const CLOSE_SESSION = "CLOSE_SESION";
export const CHANGE_PWD = "CHANGE_PWD";

//pagos
export const GET_PAYMENT = "GET_PAYMENT";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FILTER_STUDENTS = "FILTER_STUDENTS";
export const FETCH_CONCEPTS = "FETCH_CONCEPTS";
export const PAYMENTS_STATISTICS = "PAYMENTS_STATISTICS";
export const VALID_YEARS = "VALID_YEARS";
export const CLEAN_RECEIPT = "CLEAN_RECEIPT";
export const GET_REPORT_FILE = "GET_REPORT_FILE";
export const CLEAN_REPORT = "CLEAN_REPORT";

//conceptos de pago
export const FETCHALL_CONCEPTS = "FETCHALL_CONCEPTS";
export const UPDATE_STATE = "UPDATE_STATE";
export const FETCHALL_CAREERS = "FETCHALL_CAREERS";

//trabajadores
export const REGISTER_WORKER = "REGISTER_WORKER";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBCATEGORY = "FETCH_JOBCATEGORY";
export const FETCH_WORKERS = "FETCH_WORKERS";
export const HOLIDAYS_PDF = "HOLIDAYS_PDF";
export const CLEAN_FILES = "CLEAN_FILES";
export const MODIFY_WORKER = "MODIFY_WORKER";
export const SHOW_EDITWORKER = "SHOW_EDITWORKER";
export const HIDE_EDITWORKER = "HIDE_EDITWORKER";
export const GET_ONEWORKER = "GET_ONEWORKER";
export const LABORAL_PROOF = "LABORAL_PROOF";

//users
export const FETCH_USERS = "FETCH_USERS";
export const ADD_USER = "ADD_USER";
export const GET_ONEUSER = "GET_ONEUSER";
export const MODAL_USER = "MODAL_USER";
export const FETCH_STAFF = "FETCH_STAFF";
