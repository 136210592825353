import React, { useReducer } from "react";

import UsuarioContext from "./usuarioContext";
import UsuarioReducer from "./usuarioReducer";

import server from "../../../../config/axios";

import {
  REGISTER_WORKER,
  FETCH_JOBS,
  SET_MESSAGE,
  FETCH_JOBCATEGORY,
  FETCH_WORKERS,
  HOLIDAYS_PDF,
  CLEAN_FILES,
  MODIFY_WORKER,
  SHOW_EDITWORKER,
  HIDE_EDITWORKER,
  GET_ONEWORKER,
  LABORAL_PROOF,
} from "../../../../types";

const UsuarioState = (props) => {
  const initialState = {
    message: null,
    allJobs: [],
    allCategories: [],
    workers: [],
    pdfHolidays: null,
    modalEdit: false,
    worker: null,
    pdfLaboralProof: null,
  };

  const [state, dispatch] = useReducer(UsuarioReducer, initialState);

  const getJobs = async () => {
    try {
      const response = await server.get("/jobsRH");
      dispatch({
        type: FETCH_JOBS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los cargos de personal";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const addJob = async (nombre) => {
    try {
      const response = await server.post("/jobsRH", [nombre]);
      dispatch({
        type: FETCH_JOBS,
        payload: response.data,
      });
      //console.log(response.data);
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los cargos de trabajo";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getCategories = async () => {
    try {
      const response = await server.get("/employmentCategory");
      dispatch({
        type: FETCH_JOBCATEGORY,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los cargos de personal";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const addCategory = async (nombre) => {
    try {
      const response = await server.post("/employmentCategory", [nombre]);
      dispatch({
        type: FETCH_JOBCATEGORY,
        payload: response.data,
      });
      //console.log(response.data);
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener los cargos de trabajo";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const registerNewWorker = async (datos) => {
    try {
      const response = await server.post("/registerWorker", datos);
      //console.log(response.data);
      dispatch({
        type: REGISTER_WORKER,
        payload: response.data.msg,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al crear el usuario";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const modifyWorker = async (datos) => {
    try {
      const response = await server.post("/modifyWorker", datos);
      console.log(response.data);
      dispatch({
        type: MODIFY_WORKER,
        payload: response.data.msg,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al actualizar la información";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
    getWorkersList("initial-search");
  };

  const getWorkersList = async (pattern) => {
    try {
      const response = await server.get(`/fetchWorkers/${pattern}`);
      dispatch({
        type: FETCH_WORKERS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener la lista de trabajadores";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getHolidaysPDF = async (data) => {
    try {
      const response = await server({
        method: "post",
        url: `/oficioVacacionesPDF`,
        data: data,
        responseType: "blob",
      });
      //console.log(response.data);
      const file = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });
      dispatch({
        type: HOLIDAYS_PDF,
        payload: file,
      });
      //console.log(file);
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response.status === 400) {
        // console.log(error.response);
        mensaje = "El orden de las fechas es invalido";
      } else {
        mensaje = "Hubo un error al generar el oficio";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
  };

  const cleanFiles = () => {
    dispatch({
      type: CLEAN_FILES,
    });
  };

  const showModalEdit = () => {
    dispatch({
      type: SHOW_EDITWORKER,
    });
  };

  const hideModalEdit = () => {
    dispatch({
      type: HIDE_EDITWORKER,
    });
  };

  const getOneWorker = async (id) => {
    try {
      const response = await server.get(`/getWorkerById/${id}`);
      dispatch({
        type: GET_ONEWORKER,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "No se pudo obtener al trabajador";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getLaboralProofPDF = async (rfc) => {
    try {
      const response = await server({
        method: "get",
        url: `/constanciaLaboralPDF/${rfc}`,
        responseType: "blob",
      });
      //console.log(response.data);
      const file = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });
      dispatch({
        type: LABORAL_PROOF,
        payload: file,
      });
      //console.log(file);
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response.status === 400) {
        console.log(error.response);
        mensaje = "No se puede crear el documento sin la fecha de registro";
      } else {
        mensaje = "Hubo un error al generar el oficio";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
  };

  const changeStatusWorker = async (rfc) => {
    try {
      const response = await server.post(`/changeWorkerStatus/${rfc}`);
      const alerta = {
        msg: response.data.msg,
        title: "Guardado",
        category: "success",
      };
      getWorkersList("initial-search");
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    } catch (error) {
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "No se pudo cambiar el estado de " + rfc;
      }
      //console.log(error.response.data);
      const alerta = {
        msg: mensaje,
        tile: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  return (
    <UsuarioContext.Provider
      value={{
        message: state.message,
        allJobs: state.allJobs,
        allCategories: state.allCategories,
        workers: state.workers,
        pdfHolidays: state.pdfHolidays,
        modalEdit: state.modalEdit,
        worker: state.worker,
        pdfLaboralProof: state.pdfLaboralProof,
        registerNewWorker,
        getJobs,
        addJob,
        getCategories,
        addCategory,
        getWorkersList,
        getHolidaysPDF,
        cleanFiles,
        modifyWorker,
        showModalEdit,
        hideModalEdit,
        getOneWorker,
        getLaboralProofPDF,
        changeStatusWorker,
      }}
    >
      {props.children}
    </UsuarioContext.Provider>
  );
};

export default UsuarioState;
