import React, { useContext, useEffect, useState } from "react";

import AlertContext from "../../../context/alerts/alertContext";
import UsuarioContext from "../context/usuarios/usuarioContext";

import Toast from "react-bootstrap/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ReactInputDateMask from "react-input-date-mask";

const Vacaciones = () => {
  const alertContext = useContext(AlertContext);
  const { alerta, showAlert } = alertContext;

  const usuarioContext = useContext(UsuarioContext);
  const {
    message,
    pdfHolidays,
    getWorkersList,
    getHolidaysPDF,
    cleanFiles,
  } = usuarioContext;

  const holidaysPeriods = ["Semana Santa", "Verano", "Invierno"];

  useEffect(() => {
    getWorkersList("initial-search");
    if (pdfHolidays) {
      console.log("pdf");
      //Build a URL from the file
      const fileURL = URL.createObjectURL(pdfHolidays);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = "OficioVacaciones.pdf";
      a.click();
      URL.revokeObjectURL(fileURL);
      //Open the URL on new Window
      //window.open(fileURL);
      //print(fileURL);
      //print({ printable: recibo, type: "pdf", base64: true });
      cleanFiles();
    }

    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
  }, [pdfHolidays, message]);

  const [stateTrabajadores, setStateTrabajadores] = useState({
    periodoVacacional: "",
    fechaInicio: "",
    fechaFin: "",
    fechaRegreso: "",
  });

  const {
    periodoVacacional,
    fechaInicio,
    fechaFin,
    fechaRegreso,
  } = stateTrabajadores;

  const onChangeSelectHolidaysPeriod = (option) => {
    setStateTrabajadores({
      ...stateTrabajadores,
      periodoVacacional: option,
    });
  };

  const onChangeFechaInicio = (fecha) => {
    setStateTrabajadores({
      ...stateTrabajadores,
      fechaInicio: fecha,
    });
  };

  const onChangeFechaFin = (fecha) => {
    setStateTrabajadores({
      ...stateTrabajadores,
      fechaFin: fecha,
    });
  };

  const onChangeFechaRegreso = (fecha) => {
    setStateTrabajadores({
      ...stateTrabajadores,
      fechaRegreso: fecha,
    });
  };

  const onSubmitHolidays = (e) => {
    e.preventDefault();

    if (
      fechaInicio.trim() === "" ||
      fechaFin.trim() === "" ||
      fechaRegreso.trim() === "" ||
      periodoVacacional === ""
    ) {
      showAlert("Llena todos los campos", "Error", "danger");
      return;
    }
    getHolidaysPDF({
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
      periodo: periodoVacacional.value,
      fecha_regreso: fechaRegreso,
    });
    setStateTrabajadores({
      ...stateTrabajadores,
      fechaInicio: "",
      fechaFin: "",
      fechaRegreso: "",
      periodoVacacional: "",
    });
  };

  return (
    <div style={{ padding: "15px" }}>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "50px",
        }}
      >
        {alerta ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Toast
              style={
                alerta.category === "success"
                  ? { backgroundColor: "#62FF64", opacity: "0.7" }
                  : { backgroundColor: "#FF6262", opacity: "0.7" }
              }
            >
              <Toast.Body>
                <b>{alerta.title}</b>: {alerta.msg}
              </Toast.Body>
            </Toast>
          </div>
        ) : null}
      </div>
      <div
        style={{
          marginTop: "10%",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          minWidth: "350px",
        }}
      >
        <Form>
          <Row>
            <h4 style={{ marginLeft: "auto", marginRight: "auto" }}>
              Periodo Vacacional
            </h4>
          </Row>
          <Row>
            <br />
          </Row>
          <Form.Group as={Row} className="mb-3" style={{ paddingLeft: "6%" }}>
            <Form.Label column sm="5">
              Fecha inicio
            </Form.Label>
            <Col>
              <ReactInputDateMask
                id="fechaInicio"
                name="fechaInicio"
                mask="dd/mm/yyyy"
                //showMaskOnFocus={true}
                className="form-control"
                value={fechaInicio}
                //showMaskOnHover={true}
                onChange={onChangeFechaInicio}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" style={{ paddingLeft: "6%" }}>
            <Form.Label column sm="5">
              Fecha fin
            </Form.Label>
            <Col>
              <ReactInputDateMask
                id="fechaFin"
                name="fechaFin"
                mask="dd/mm/yyyy"
                //showMaskOnFocus={true}
                className="form-control"
                value={fechaFin}
                //showMaskOnHover={true}
                onChange={onChangeFechaFin}
              />
            </Col>
          </Form.Group>

          <Row>
            <br />
          </Row>
          <Form.Group as={Row} className="mb-3" style={{ paddingLeft: "6%" }}>
            <Form.Label column sm="5">
              Fecha Regreso
            </Form.Label>
            <Col>
              <ReactInputDateMask
                id="fechaRegreso"
                name="fechaRegreso"
                mask="dd/mm/yyyy"
                //showMaskOnFocus={true}
                className="form-control"
                value={fechaRegreso}
                //showMaskOnHover={true}
                onChange={onChangeFechaRegreso}
              />
            </Col>
          </Form.Group>
          <Row>
            <br />
          </Row>
          <Row>
            <h4 style={{ marginLeft: "auto", marginRight: "auto" }}>
              Tipo de periodo
            </h4>
          </Row>
          <Row>
            <Col>
              <Select
                id="tipoPeriodo"
                value={periodoVacacional}
                options={holidaysPeriods.map((period) => ({
                  value: period,
                  label: period,
                }))}
                name="tipoPeriodo"
                onChange={onChangeSelectHolidaysPeriod}
                placeholder="Selecciona"
              />
            </Col>
          </Row>
          <Row>
            <br />
            <br />
          </Row>
          <Row
            style={{
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Col>
              <Button
                // style={{ width: "100px" }}
                type="submit"
                onClick={onSubmitHolidays}
                block
              >
                Generar Oficio
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Vacaciones;
