import {
  SET_MESSAGE,
  FETCHALL_CONCEPTS,
  FETCHALL_CAREERS,
} from "../../../../types";

export default (state, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case FETCHALL_CONCEPTS:
      return {
        ...state,
        allconcepts: action.payload,
      };
    case FETCHALL_CAREERS:
      return {
        ...state,
        allCareers: action.payload,
      };
    default:
      return state;
  }
};
