import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";

const PrivateRoute = ({ component: Component, ...props }) => {
  const authContext = useContext(AuthContext);
  const { authenticated, rol, loading, authenticatedUser } = authContext;
  let allowed = false;

  useEffect(() => {
    authenticatedUser();
  }, []);

  if (props.roles.includes(rol)) {
    allowed = true;
  }

  return (
    <Route
      {...props}
      render={(props) =>
        authenticated && allowed && !loading ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
