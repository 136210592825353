import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./Pages/login/Login";
import PwdChange from "./Pages/login/PwdChange";
import MainRecibos from "./Pages/recibos/MainRecibos";
import MainRH from "./Pages/rh/MainRH";
import MainAdmin from "./Pages/admin/MainAdmin";
import MainCAE from "./Pages/cae/MainCAE";

import AuthState from "./context/auth/authState";
import AlertState from "./context/alerts/alertState";

import tokenAuth from "./config/auth";

import PrivateRoute from "./Pages/route/PrivateRoute";
import { roles } from "./config/constants";

//Revisar si hay un token
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

function App() {
  return (
    <Router>
      <AuthState>
        <AlertState>
          <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute
              path="/user/pwd-change"
              component={PwdChange}
              roles={roles.all}
            />
            <PrivateRoute
              path="/recibos"
              component={MainRecibos}
              roles={[
                roles.JefeAdministrativo,
                roles.CoordinadorAdministrativo,
              ]}
            />
            <PrivateRoute
              path="/rh"
              component={MainRH}
              roles={[roles.RecursosHumanos]}
            />
            <PrivateRoute
              path="/admin"
              component={MainAdmin}
              roles={[roles.Administrador]}
            />
            <PrivateRoute path="/cae" component={MainCAE} roles={[roles.cae]} />
          </Switch>
        </AlertState>
      </AuthState>
    </Router>
  );
}

export default App;
