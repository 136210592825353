import React, { useContext, useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import ReactInputDateMask from "react-input-date-mask";

import AlertContext from "../../../context/alerts/alertContext";
import AuthContext from "../../../context/auth/authContext";
import UsuarioContext from "../context/usuarios/usuarioContext";

const ModalEditTrabajador = ({ trabajador }) => {
  const alertContext = useContext(AlertContext);
  const { showAlert } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { authenticatedUser } = authContext;

  const typeContract = [
    { value: "Base", label: "Base" },
    { value: "Comisión", label: "Comisión" },
    { value: "Honorarios", label: "Honorarios" },
  ];

  const grades = [
    { value: "Secundaria", label: "Secundaria" },
    { value: "Bachillerato", label: "Bachillerato" },
    { value: "Técnico", label: "Técnico" },
    { value: "Licenciatura", label: "Licenciatura" },
    { value: "Escpecialidad", label: "Escpecialidad" },
    { value: "Maestría", label: "Maestría" },
    { value: "Doctorado", label: "Doctorado" },
    { value: "Postoctorado", label: "Postoctorado" },
  ];

  const curpPattern = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

  const usuarioContext = useContext(UsuarioContext);
  const {
    message,
    allJobs,
    allCategories,
    getJobs,
    getCategories,
    modifyWorker,
    hideModalEdit,
  } = usuarioContext;

  useEffect(() => {
    authenticatedUser();
    getJobs();
    getCategories();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
    if (trabajador) {
      // console.log(trabajador);
      setUsuario({
        ...usuario,
        apellidoPaterno: trabajador.apePaterno,
        apellidoMaterno: trabajador.apeMaterno,
        nombre: trabajador.nombre,
        curp: trabajador.curp,
        rfc: trabajador.rfc,
        telefono: trabajador.tel,
        correo: trabajador.correo,
        puesto: { label: trabajador.puesto, value: trabajador.puesto },
        categoriaPlaza: {
          label: trabajador.categoria_plaza,
          value: trabajador.categoria_plaza,
        },
        clavePlaza: trabajador.clave_plaza,
        contrato: { value: trabajador.contrato, label: trabajador.contrato },
        gradoEstudios: {
          label: trabajador.estudios,
          value: trabajador.estudios,
        },
        horasSemana: trabajador.horasDeTrabajo,
        fechaIngreso: trabajador.ingreso,
        cedula: trabajador.cedula,
        idWorker: trabajador.id,
      });
    }
  }, [message, trabajador]);

  //state para usuarios
  const [usuario, setUsuario] = useState({
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombre: "",
    curp: "",
    rfc: "",
    telefono: "",
    correo: "",
    puesto: "",
    fechaIngreso: "",
    gradoEstudios: "",
    numSeguro: "",
    contrato: "",
    horasSemana: "",
    archivoEstudios: "",
    clavePlaza: "",
    categoriaPlaza: "",
    cedula: "",
    idWorker: "",
  });

  //extraer del trabajador
  const {
    apellidoPaterno,
    apellidoMaterno,
    nombre,
    curp,
    rfc,
    telefono,
    correo,
    puesto,
    fechaIngreso,
    gradoEstudios,
    numSeguro,
    contrato,
    horasSemana,
    archivoEstudios,
    clavePlaza,
    categoriaPlaza,
    cedula,
    idWorker,
  } = usuario;

  const onChangeFields = (e) => {
    if (e.target.name === "horasSemana") {
      if (isNaN(e.target.value)) {
        return;
      }
    }
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDocument = (e) => {
    setUsuario({
      ...usuario,
      archivoEstudios: e.target.files[0],
    });
  };

  const onChangeSelectPuesto = (option) => {
    setUsuario({
      ...usuario,
      puesto: option,
    });
  };

  const onChangeSelectCategoria = (option) => {
    setUsuario({
      ...usuario,
      categoriaPlaza: option,
    });
  };

  const onChangeSelectContrato = (option) => {
    setUsuario({
      ...usuario,
      contrato: option,
    });
  };

  const onChangeSelectGrado = (option) => {
    setUsuario({
      ...usuario,
      gradoEstudios: option,
    });
  };

  const onChangeFecha = (fecha) => {
    setUsuario({
      ...usuario,
      fechaIngreso: fecha,
    });
  };

  const onSubmitUser = (e) => {
    e.preventDefault();

    let data = new FormData();

    if (
      apellidoPaterno.trim() === "" ||
      apellidoMaterno.trim() === "" ||
      nombre.trim() === "" ||
      curp.trim() === "" ||
      rfc.trim() === "" ||
      telefono.trim() === "" ||
      correo.trim() === "" ||
      puesto === "" ||
      fechaIngreso.trim() === "" ||
      gradoEstudios === "" ||
      contrato === "" ||
      horasSemana === ""
    ) {
      showAlert("Llena todos los campos", "Error", "danger");
      return;
    }

    if (!curp.match(curpPattern)) {
      showAlert("El formato CURP es invalido", "Error", "danger");
      return;
    }

    data.append("id", idWorker);
    data.append("apellidoPat", apellidoPaterno);
    data.append("apellidoMat", apellidoMaterno);
    data.append("nombre", nombre);
    data.append("curp", curp);
    data.append("rfc", rfc);
    data.append("telefono", telefono);
    data.append("correo", correo);
    data.append("puestoLabel", puesto.label);
    data.append("puestoValue", puesto.value);
    data.append("fechaIngreso", fechaIngreso);
    data.append("gradoEstudios", gradoEstudios.value);
    data.append("numSeguro", numSeguro);
    data.append("contrato", contrato.value);
    data.append("horasSemana", horasSemana);
    data.append("clavePlaza", clavePlaza);
    data.append("categoriaPlaza", categoriaPlaza.value);
    data.append("cedula", cedula);
    data.append("file", archivoEstudios);

    modifyWorker(data);
    hideModalEdit();
  };

  const leftSpace = { paddingLeft: "4%" };
  const rightSpace = {};
  const labelSize = 6;

  return (
    <div>
      <Form>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Primer Apellido
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="apellidoPaterno"
              name="apellidoPaterno"
              value={apellidoPaterno}
              placeholder="Apellido Paterno"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Segundo Apellido
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="apellidoMaterno"
              name="apellidoMaterno"
              value={apellidoMaterno}
              placeholder="Apellido Materno"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Nombre
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="nombre"
              name="nombre"
              value={nombre}
              placeholder="Nombre(s)"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            CURP
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="curp"
              name="curp"
              value={curp}
              placeholder="CURP"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            RFC
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="rfc"
              name="rfc"
              value={rfc}
              placeholder="RFC"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Telefono
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="telefono"
              name="telefono"
              value={telefono}
              placeholder="telefono"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            e-mail
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="email"
              id="correo"
              name="correo"
              value={correo}
              placeholder="Correo Electronico"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Cargo
          </Form.Label>
          <Col style={rightSpace}>
            <Select
              isDisabled={trabajador.rol === -1 ? false : true}
              id="puesto"
              name="puesto"
              onChange={onChangeSelectPuesto}
              placeholder="Cargo"
              isSearchable={true}
              value={puesto}
              options={allJobs.map((job) => ({
                value: job.id,
                label: job.cargo,
              }))}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Fecha Ingreso
          </Form.Label>
          <Col style={rightSpace}>
            <ReactInputDateMask
              id="fechaIngreso"
              name="fechaIngreso"
              mask="dd/mm/yyyy"
              //showMaskOnFocus={true}
              className="form-control"
              value={fechaIngreso}
              //showMaskOnHover={true}
              onChange={onChangeFecha}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Tipo de Contrato
          </Form.Label>
          <Col style={rightSpace}>
            <Select
              id="contrato"
              name="contrato"
              onChange={onChangeSelectContrato}
              placeholder="Tipo de contrato"
              value={contrato}
              options={typeContract}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Horas por Semana
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="horasSemana"
              id="horasSemana"
              name="horasSemana"
              value={horasSemana}
              placeholder="Horas por Semana"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Numero de Seguro
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="numSeguro"
              name="numSeguro"
              value={numSeguro}
              placeholder="Numero de Seguro"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Grado de Estudios
          </Form.Label>
          <Col style={rightSpace}>
            <Select
              id="gradoEstudios"
              name="gradoEstudios"
              onChange={onChangeSelectGrado}
              placeholder="Último grado de estudios"
              value={gradoEstudios}
              options={grades}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Archivo
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="file"
              id="archivoEstudios"
              name="archivoEstudios"
              onChange={onChangeDocument}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Cedula Profesional
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="cedula"
              name="cedula"
              value={cedula}
              placeholder="Cedula Profesional"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Clave de Plaza
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="clavePlaza"
              name="clavePlaza"
              value={clavePlaza}
              placeholder="Clave de plaza"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Categoria
          </Form.Label>
          <Col style={rightSpace}>
            <Select
              id="categoriaPlaza"
              name="categoriaPlaza"
              onChange={onChangeSelectCategoria}
              placeholder="Categoria"
              isSearchable={true}
              value={categoriaPlaza}
              options={allCategories.map((cat) => ({
                value: cat,
                label: cat,
              }))}
            />
          </Col>
        </Form.Group>
        <Row>
          <br />
        </Row>
        <Row>
          <Col xs={2} block>
            <Button
              style={{ width: "100px" }}
              type="submit"
              onClick={onSubmitUser}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ModalEditTrabajador;
