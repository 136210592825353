import {
  FETCH_JOBCATEGORY,
  FETCH_JOBS,
  FETCH_WORKERS,
  REGISTER_WORKER,
  SET_MESSAGE,
  HOLIDAYS_PDF,
  CLEAN_FILES,
  MODIFY_WORKER,
  SHOW_EDITWORKER,
  HIDE_EDITWORKER,
  GET_ONEWORKER,
  LABORAL_PROOF,
} from "../../../../types";

export default (state, action) => {
  switch (action.type) {
    case REGISTER_WORKER:
    case MODIFY_WORKER:
      return {
        ...state,
        message: {
          msg: action.payload.msg,
          title: "Listo!",
          category: "success",
        },
      };
    case FETCH_JOBS:
      return {
        ...state,
        allJobs: action.payload,
      };
    case FETCH_JOBCATEGORY:
      return {
        ...state,
        allCategories: action.payload,
      };
    case FETCH_WORKERS:
      return {
        ...state,
        workers: action.payload,
      };
    case HOLIDAYS_PDF:
      console.log("vacaciones");
      return {
        ...state,
        pdfHolidays: action.payload,
      };
    case CLEAN_FILES:
      return {
        ...state,
        pdfHolidays: null,
        pdfLaboralProof: null,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case SHOW_EDITWORKER:
      return {
        ...state,
        modalEdit: true,
      };
    case HIDE_EDITWORKER:
      return {
        ...state,
        modalEdit: false,
      };
    case GET_ONEWORKER:
      return {
        ...state,
        worker: action.payload,
      };
    case LABORAL_PROOF:
      console.log("vacaciones");
      return {
        ...state,
        pdfLaboralProof: action.payload,
      };
    default:
      return state;
  }
};
