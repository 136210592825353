export const columnsTotal = [
  {
    name: "Subtotal",
    selector: "subtotal",
    sortable: "true",
    //width: "70px",
    center: "true",
  },
  {
    name: "Cancelado",
    selector: "cancelado",
    sortable: "true",
    center: "true",
  },
  {
    name: "TOTAL",
    selector: "total",
    sortable: "true",
    center: "true",
  },
];

export const columnsTotalCareers = [
  {
    name: "Clave",
    selector: "clave",
    sortable: "true",
    center: "true",
  },
  {
    name: "Programa",
    selector: "nombre",
    sortable: "true",
    center: "true",
  },
  {
    name: "Total",
    selector: "total",
    sortable: "true",
    center: "true",
  },
];

export const columnsTotalConcepts = [
  {
    name: "Código",
    selector: "codigo",
    sortable: "true",
    center: "true",
  },
  {
    name: "Descripción",
    selector: "nombre",
    sortable: "true",
    center: "true",
  },
  {
    name: "Total",
    selector: "total",
    sortable: "true",
    center: "true",
  },
];
