import React, { useReducer } from "react";

import UserContext from "./userContext";
import UserReducer from "./userReducer";

import server from "../../../../config/axios";

import {
  FETCH_USERS,
  ADD_USER,
  GET_ONEUSER,
  SET_MESSAGE,
  MODAL_USER,
  FETCH_STAFF,
  FETCH_JOBS,
} from "../../../../types/index";

const UserState = (props) => {
  const initialState = {
    message: null,
    users: [],
    showModal: false,
    user: null,
    staff: [],
    jobs: [],
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const addUser = async (datos) => {
    try {
      const response = await server.post("/addNewUser", datos);
      //console.log(response.data);
      dispatch({
        type: ADD_USER,
        payload: response.data.msg,
      });
      getUsers("initial-search");
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al crear el usuario";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  //   const modifyWorker = async (datos) => {
  //     try {
  //       const response = await server.post("/modifyWorker", datos);
  //       console.log(response.data);
  //       dispatch({
  //         type: MODIFY_WORKER,
  //         payload: response.data.msg,
  //       });
  //     } catch (error) {
  //       //console.log(error.response.data);
  //       let mensaje;
  //       if (error.response) {
  //         mensaje = error.response.data.msg;
  //       } else {
  //         mensaje = "Hubo un error al actualizar la información";
  //       }
  //       const alerta = {
  //         msg: mensaje,
  //         title: "Error",
  //         category: "danger",
  //       };
  //       dispatch({
  //         type: SET_MESSAGE,
  //         payload: alerta,
  //       });
  //     }
  //     getWorkersList("initial-search");
  //   };

  const getUsers = async (pattern) => {
    try {
      const response = await server.get(`/fetchUsers/${pattern}`);
      dispatch({
        type: FETCH_USERS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener la lista de usuarios";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const changeStateModalUser = (state) => {
    dispatch({
      type: MODAL_USER,
      payload: state,
    });
  };

  const getUser = async (id) => {
    try {
      const response = await server.get(`/getWorkerById/${id}`);
      //console.log(response.data);
      dispatch({
        type: GET_ONEUSER,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "No se pudo obtener al trabajador";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const changeStatusUser = async (rfc) => {
    try {
      const response = await server.post(`/changeWorkerStatus/${rfc}`);
      const alerta = {
        msg: response.data.msg,
        title: "Guardado",
        category: "success",
      };
      getUsers("initial-search");
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    } catch (error) {
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "No se pudo cambiar el estado de " + rfc;
      }
      //console.log(error.response.data);
      const alerta = {
        msg: mensaje,
        tile: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getJobs = async () => {
    try {
      const response = await server.get("/jobsRH");
      dispatch({
        type: FETCH_JOBS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los cargos de personal";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getStaffList = async () => {
    try {
      const response = await server.get(`/fetchStaffList`);
      dispatch({
        type: FETCH_STAFF,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
        console.log(mensaje);
      } else {
        mensaje = "Hubo un error al obtener todo el personal";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const jobAssignment = async (data) => {
    try {
      const response = await server.post(`/updateJob`, data);
      dispatch({
        type: SET_MESSAGE,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
        console.log(mensaje);
      } else {
        mensaje = "Hubo un error al obtener todo el personal";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        message: state.message,
        users: state.users,
        showModal: state.showModal,
        user: state.user,
        staff: state.staff,
        jobs: state.jobs,
        addUser,
        getUsers,
        getUser,
        changeStatusUser,
        changeStateModalUser,
        getStaffList,
        getJobs,
        jobAssignment,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
