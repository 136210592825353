import {
  GET_PAYMENT,
  CREATE_PAYMENT,
  DELETE_PAYMENT,
  FETCH_PAYMENTS,
  FETCH_STUDENTS,
  FILTER_STUDENTS,
  FETCH_CONCEPTS,
  SET_MESSAGE,
  PAYMENTS_STATISTICS,
  VALID_YEARS,
  CLEAN_RECEIPT,
  GET_REPORT_FILE,
  CLEAN_REPORT,
} from "../../../../types";

export default (state, action) => {
  switch (action.type) {
    case CREATE_PAYMENT:
      return {
        ...state,
        recibo: action.payload,
        // message: {
        //   msg: action.payload.msg,
        //   title: "Listo!",
        //   category: "success",
        // },
      };
    case FETCH_PAYMENTS:
      return {
        ...state,
        paymentsList: action.payload,
      };
    case GET_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
    case DELETE_PAYMENT:
      return {
        ...state,
        payment: null,
        message: action.payload,
      };
    case FETCH_STUDENTS:
      return {
        ...state,
        students: action.payload,
      };
    case FILTER_STUDENTS:
      return {
        ...state,
        filteredStudents: action.payload,
      };
    case FETCH_CONCEPTS:
      return {
        ...state,
        cobros: action.payload.cobros,
        descuentos: action.payload.descuentos,
      };
    case SET_MESSAGE:
      // console.log("err");
      return {
        ...state,
        message: action.payload,
      };
    case VALID_YEARS:
      return {
        ...state,
        years: action.payload,
      };
    case PAYMENTS_STATISTICS:
      return {
        ...state,
        paymentsReport: action.payload,
      };
    case CLEAN_RECEIPT:
      return {
        ...state,
        recibo: null,
      };
    case GET_REPORT_FILE:
      return {
        ...state,
        reporte: action.payload,
      };
    case CLEAN_REPORT:
      return {
        ...state,
        reporte: null,
      };
    default:
      return state;
  }
};
