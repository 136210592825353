import React, { useState, useContext, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";

import { roles } from "../../../config/constants";

import AlertContext from "../../../context/alerts/alertContext";
import AuthContext from "../../../context/auth/authContext";
import AssignmentContext from "../context/assignmentContext";

const PrestamoBien = () => {
  const alertContext = useContext(AlertContext);
  const { showAlert } = alertContext;

  //Extraer la información de autenticacion
  const authContext = useContext(AuthContext);
  const { authenticatedUser } = authContext;

  const assignmentContext = useContext(AssignmentContext);
  const { message } = assignmentContext;

  useEffect(() => {
    authenticatedUser();
    if (message) {
      showAlert(message.msg, message.title, message.category);
    }
  }, [message]);

  //state para usuarios
  const [asignacion, setAsignacion] = useState({});

  //extraer del usuario
  const {} = asignacion;

  //   const onChangeFields = (e) => {
  //     setUsuario({
  //       ...usuario,
  //       [e.target.name]: e.target.value,
  //     });
  //   };

  //   const onChangeSelectPuesto = (option) => {
  //     setUsuario({
  //       ...usuario,
  //       puesto: option,
  //     });
  //   };

  //   const onSubmitUser = (e) => {
  //     e.preventDefault();

  //     if (
  //       apellidoPaterno.trim() === "" ||
  //       apellidoMaterno.trim() === "" ||
  //       nombre.trim() === "" ||
  //       curp.trim() === "" ||
  //       rfc.trim() === "" ||
  //       correo.trim() === "" ||
  //       puesto === ""
  //     ) {
  //       showAlert("Llena todos los campos", "Error", "danger");
  //       return;
  //     }

  //     if (!curp.match(curpPattern)) {
  //       showAlert("El formato CURP es invalido", "Error", "danger");
  //       return;
  //     }
  //     addUser({
  //       rfc,
  //       curp,
  //       puesto,
  //       apellidoPaterno,
  //       apellidoMaterno,
  //       nombre,
  //       correo,
  //     });
  //     changeStateModalUser(false);
  //   };

  const leftSpace = { paddingLeft: "8%" };
  const rightSpace = { paddingRight: "8%" };
  const labelSize = 5;
  const fieldSize = 7;

  return (
    <div style={{ width: "100%" }}>
      {/* <Form>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Primer Apellido
          </Form.Label>
          <Col style={rightSpace} sm={fieldSize}>
            <Form.Control
              type="text"
              id="apellidoPaterno"
              name="apellidoPaterno"
              value={apellidoPaterno}
              placeholder="Apellido Paterno"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Segundo Apellido
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="apellidoMaterno"
              name="apellidoMaterno"
              value={apellidoMaterno}
              placeholder="Apellido Materno"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Nombre
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="nombre"
              name="nombre"
              value={nombre}
              placeholder="Nombre(s)"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            CURP
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="curp"
              name="curp"
              value={curp}
              placeholder="CURP"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            RFC
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="text"
              id="rfc"
              name="rfc"
              value={rfc}
              placeholder="RFC"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            e-mail
          </Form.Label>
          <Col style={rightSpace}>
            <Form.Control
              type="email"
              id="correo"
              name="correo"
              value={correo}
              placeholder="Correo Electronico"
              onChange={onChangeFields}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={leftSpace}>
          <Form.Label column sm={labelSize}>
            Tipo usuario
          </Form.Label>
          <Col style={rightSpace}>
            <Select
              id="puesto"
              name="puesto"
              onChange={onChangeSelectPuesto}
              placeholder="Tipo de Usuario"
              isSearchable={true}
              value={puesto}
              options={allowedJobs.map((job) => ({
                value: job.value,
                label: job.label,
              }))}
            />
          </Col>
        </Form.Group>
        <Row>
          <br />
        </Row>
        <Row>
          <Col xs={{ span: 3, offset: 4 }}>
            <Button
              // style={{ width: "100px"}}
              type="submit"
              onClick={onSubmitUser}
              block
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form> */}
    </div>
  );
};
export default PrestamoBien;
