import React, { useReducer } from "react";

import PagoContext from "./pagoContext";
import PagoReducer from "./pagoReducer";

import server from "../../../../config/axios";

import {
  GET_PAYMENT,
  CREATE_PAYMENT,
  DELETE_PAYMENT,
  FETCH_PAYMENTS,
  FETCH_STUDENTS,
  FILTER_STUDENTS,
  FETCH_CONCEPTS,
  SET_MESSAGE,
  PAYMENTS_STATISTICS,
  // VALID_YEARS,
  CLEAN_RECEIPT,
  GET_REPORT_FILE,
  CLEAN_REPORT,
} from "../../../../types";

const PagoState = (props) => {
  const initialState = {
    students: [],
    filteredStudents: [],
    descuentos: [],
    cobros: [],
    recibo: null,
    payment: null,
    message: null,
    paymentsList: [],
    paymentsReport: null,
    reporte: null,
    // years: [],
    // total_statistics: 0,
    // month_statistics: [],
    // career_statistics: [],
    // concept_statistics: [],
  };

  const [state, dispatch] = useReducer(PagoReducer, initialState);

  // functions
  const getStudentsList = async () => {
    try {
      const response = await server.get("/fetchStudentsNames");
      dispatch({
        type: FETCH_STUDENTS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los estudiantes";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getPaymentsList = async (pattern) => {
    try {
      const response = await server.get(`/fetchPhisicalPayments/${pattern}`);
      dispatch({
        type: FETCH_PAYMENTS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener lista de pagos";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getOnePayment = async (pattern) => {
    try {
      const response = await server.get(`/getPaymentByFolio/${pattern}`);
      dispatch({
        type: GET_PAYMENT,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener lista de pagos";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const deletePayment = async (folio) => {
    try {
      const response = await server.delete(`/deletePhisicalPayment/${folio}`);

      const alerta = {
        msg: response.data.msg,
        title: "Listo",
        category: "success",
      };
      dispatch({
        type: DELETE_PAYMENT,
        payload: alerta,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al cancelar el pago";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const getConcepts = async () => {
    try {
      const response = await server.get("/getActiveConcepts");
      dispatch({
        type: FETCH_CONCEPTS,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data;
      } else {
        mensaje = "Hubo un error al obtener los conceptso de pago";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
    }
  };

  const filterStudents = (filter, students) => {
    if (isNaN(filter)) {
      const filtered = students.filter((student) => {
        return student.nombre.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
      dispatch({
        type: FILTER_STUDENTS,
        payload: filtered,
      });
    } else {
      const filtered = students.filter((student) => {
        return student.matricula.indexOf(filter) >= 0;
      });
      dispatch({
        type: FILTER_STUDENTS,
        payload: filtered,
      });
    }
  };

  const savePayment = async (datos) => {
    try {
      const response = await server({
        method: "post",
        url: "/createPayment",
        data: datos,
        responseType: "blob",
      });
      //console.log(response.data);
      const file = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });
      dispatch({
        type: CREATE_PAYMENT,
        payload: file,
      });
      //console.log(file);
    } catch (error) {
      //console.log(error.response.data);
      // let mensaje;
      // if (error.response) {
      //   mensaje = error.response.data;
      // } else {
      //   mensaje = "Hubo un error al crear el pago";
      // }
      const alerta = {
        msg: "Hubo un error al crear el pago",
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
  };

  const getRecibo = async (folio) => {
    try {
      const response = await server({
        method: "get",
        url: `/getReceipt/${folio}`,
        data: folio,
        responseType: "blob",
      });
      //console.log(response.data);
      const file = new Blob([response.data], {
        type: "application/pdf;charset=utf-8",
      });
      dispatch({
        type: CREATE_PAYMENT,
        payload: file,
      });
      //console.log(file);
    } catch (error) {
      //console.log(error.response.data);
      // let mensaje;
      // if (error.response) {
      //   mensaje = error.response.data;
      // } else {
      //   mensaje = "Hubo un error al crear el pago";
      // }
      const alerta = {
        msg: "Hubo un error al imprimir el recibo",
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
  };

  const cleanReceipt = () => {
    dispatch({
      type: CLEAN_RECEIPT,
    });
  };

  // const getStatistics = async (year) => {
  //   try {
  //     const response = await server.get(`/getPaymentStatistics/${year}`);
  //     //console.log(response.data);
  //     dispatch({
  //       type: PAYMENTS_STATISTICS,
  //       payload: response.data,
  //     });
  //     //console.log(file);
  //   } catch (error) {
  //     //console.log(error.response.data);
  //     // let mensaje;
  //     // if (error.response) {
  //     //   mensaje = error.response.data;
  //     // } else {
  //     //   mensaje = "Hubo un error al crear el pago";
  //     // }
  //     const alerta = {
  //       msg: "Hubo un error al obtener los datos",
  //       title: "Error",
  //       category: "danger",
  //     };
  //     // console.log(alerta);
  //     dispatch({
  //       type: SET_MESSAGE,
  //       payload: alerta,
  //     });
  //     // console.log(state.message);
  //   }
  // };

  // const getYears = async () => {
  //   try {
  //     const response = await server.get(`/getValidYears`);
  //     //console.log(response.data);
  //     dispatch({
  //       type: VALID_YEARS,
  //       payload: response.data,
  //     });
  //     //console.log(file);
  //   } catch (error) {
  //     //console.log(error.response.data);
  //     // let mensaje;
  //     // if (error.response) {
  //     //   mensaje = error.response.data;
  //     // } else {
  //     //   mensaje = "Hubo un error al crear el pago";
  //     // }
  //     const alerta = {
  //       msg: "Hubo un error al obtener los años validos",
  //       title: "Error",
  //       category: "danger",
  //     };
  //     // console.log(alerta);
  //     dispatch({
  //       type: SET_MESSAGE,
  //       payload: alerta,
  //     });
  //     // console.log(state.message);
  //   }
  // };

  const getPaymentsReport = async (interval) => {
    //console.log(interval);
    try {
      const response = await server.get(`/getPaymentsReport`, {
        params: interval,
      });
      //console.log(response.data);
      dispatch({
        type: PAYMENTS_STATISTICS,
        payload: response.data,
      });
      //console.log(file);
    } catch (error) {
      // console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error al obtener los datos del reporte";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
  };

  const getReportFile = async (period) => {
    try {
      const response = await server({
        method: "get",
        url: `/getPaymentsReportXlsx`,
        params: period,
        responseType: "blob",
        headers: { Accept: "application/vnd.ms-excel" },
      });
      //console.log(response.data);
      const file = new Blob([response.data]);
      dispatch({
        type: GET_REPORT_FILE,
        payload: file,
      });
      console.log(file);
    } catch (error) {
      //console.log(error.response.data);
      // let mensaje;
      // if (error.response) {
      //   mensaje = error.response.data;
      // } else {
      //   mensaje = "Hubo un error al crear el pago";
      // }
      const alerta = {
        msg: "Hubo un error al obtener el archivo",
        title: "Error",
        category: "danger",
      };
      // console.log(alerta);
      dispatch({
        type: SET_MESSAGE,
        payload: alerta,
      });
      // console.log(state.message);
    }
  };

  const cleanReport = () => {
    dispatch({
      type: CLEAN_REPORT,
    });
  };

  return (
    <PagoContext.Provider
      value={{
        message: state.message,
        payment: state.payment,
        students: state.students,
        descuentos: state.descuentos,
        cobros: state.cobros,
        filteredStudents: state.filteredStudents,
        paymentsList: state.paymentsList,
        recibo: state.recibo,
        // years: state.years,
        // total_statistics: state.total_statistics,
        // month_statistics: state.month_statistics,
        // career_statistics: state.career_statistics,
        // concept_statistics: state.concept_statistics,
        paymentsReport: state.paymentsReport,
        reporte: state.reporte,
        getStudentsList,
        filterStudents,
        getConcepts,
        savePayment,
        getPaymentsList,
        getOnePayment,
        deletePayment,
        // getYears,
        // getStatistics,
        getPaymentsReport,
        getRecibo,
        cleanReceipt,
        getReportFile,
        cleanReport,
      }}
    >
      {props.children}
    </PagoContext.Provider>
  );
};

export default PagoState;
