import React, { useReducer } from "react";

import AuthContext from "./authContext";
import AuthReducer from "./authReducer";

import server from "../../config/axios";
import tokenAuth from "../../config/auth";

import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  GET_USER,
  CLOSE_SESSION,
  CHANGE_PWD,
} from "../../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    authenticated: false,
    rol: null,
    user: null,
    message: null,
    loading: true,
    firstFlag: false,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // functions
  const iniciarSesion = async (datos) => {
    try {
      const response = await server.post("/login", datos);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });

      //Obtener el usuario
      authenticatedUser();
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error de servidor";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
    }
  };

  const changePwd = async (data) => {
    try {
      const response = await server.post("/changePassword", data);
      dispatch({
        type: CHANGE_PWD,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error.response.data);
      let mensaje;
      if (error.response) {
        mensaje = error.response.data.msg;
      } else {
        mensaje = "Hubo un error de servidor";
      }
      const alerta = {
        msg: mensaje,
        title: "Error",
        category: "danger",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
    }
  };

  //Regresa el usuario autenticado
  const authenticatedUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      //enviar token por headers
      tokenAuth(token);
    }

    try {
      const response = await server.get("/getAuthUser");
      dispatch({
        type: GET_USER,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };

  //Cierra la sesión
  const closeSession = () => {
    dispatch({
      type: CLOSE_SESSION,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        authenticated: state.authenticated,
        rol: state.rol,
        user: state.user,
        message: state.message,
        loading: state.loading,
        firstFlag: state.firstFlag,
        iniciarSesion,
        authenticatedUser,
        closeSession,
        changePwd,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
